<template>
  <div class="container-icon" :style="propContainer" @click="clickMe()">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="10" :fill="background" />
      <path
        d="M32.8184 29.8155C33.5403 30.518 34.233 31.2009 34.9257 31.8936C35.4915 32.4594 35.4037 32.9374 34.6818 33.3082C33.9013 33.7082 33.1013 34.0789 32.3403 34.4984C32.1159 34.6155 31.9111 34.8399 31.7842 35.0642C31.3647 35.835 30.994 36.6252 30.594 37.4057C30.233 38.1179 29.7452 38.1861 29.1794 37.6203C27.9599 36.4008 26.7404 35.1813 25.5209 33.9618C25.4038 33.8447 25.2868 33.7374 25.0526 33.5228C24.8868 33.7472 24.7502 33.9716 24.5746 34.1569C23.4234 35.3277 22.2624 36.4886 21.0917 37.6398C20.5454 38.1862 20.0478 38.1081 19.7064 37.4252C19.3064 36.6252 18.9259 35.8252 18.4966 35.035C18.3795 34.8203 18.1844 34.6155 17.9698 34.5082C17.1698 34.0789 16.3405 33.6887 15.5406 33.2789C14.9064 32.957 14.8284 32.4496 15.3162 31.9521C16.0186 31.2399 16.7405 30.5277 17.4722 29.796C15.482 27.4448 14.6625 24.7717 15.1308 21.7571C15.5308 19.1523 16.7405 16.9669 18.7698 15.2791C22.5453 12.128 28.2331 12.2743 31.833 15.5328C35.472 18.8303 36.8476 25.0936 32.8184 29.8155ZM25.1404 31.757C30.0867 31.757 33.8037 28.0399 33.8135 23.0742C33.8232 18.3815 29.8818 14.4791 25.1404 14.4791C20.399 14.4791 16.4576 18.3913 16.4674 23.0742C16.4869 28.0302 20.2039 31.757 25.1404 31.757ZM23.3844 33.1326C21.5697 32.8106 19.9503 32.0204 18.5942 30.8887C18.0674 31.4058 17.5796 31.8936 17.0625 32.3911C17.5015 32.6155 17.9405 32.8789 18.4186 33.0643C19.1405 33.3472 19.6283 33.8155 19.9112 34.5472C20.0966 35.035 20.3795 35.4935 20.5941 35.913C21.5405 34.9764 22.4575 34.0594 23.3844 33.1326ZM26.916 33.1326C27.8526 34.0691 28.7599 34.9764 29.6965 35.913C29.9306 35.4545 30.2135 34.9862 30.4087 34.4789C30.6721 33.796 31.1306 33.3667 31.8038 33.0935C32.3013 32.8887 32.7794 32.6155 33.2379 32.3813C32.7208 31.8643 32.2428 31.3765 31.7257 30.8594C30.3306 32.0399 28.7111 32.8106 26.916 33.1326Z"
        :fill="color"
      />
      <path
        d="M27.8721 20.0596C28.6038 20.0596 29.2965 20.0596 29.9989 20.0596C30.7696 20.0596 31.0233 20.3717 30.9355 21.1132C30.6916 23.0449 30.4574 24.9766 30.2135 26.918C30.1355 27.5912 29.9111 27.8058 29.2379 27.8058C26.2429 27.8058 23.2575 27.8058 20.2624 27.8058C19.5698 27.8058 19.3064 27.5424 19.3064 26.8692C19.2966 25.4351 19.2966 24.0107 19.3064 22.5766C19.3064 21.8937 19.5698 21.6303 20.2527 21.6303C20.9649 21.6205 21.6868 21.6498 22.399 21.6108C22.5649 21.601 22.7892 21.4644 22.8673 21.3181C23.4917 20.1864 24.0087 19.0254 24.0185 17.6889C24.0282 17.1133 24.4087 16.8596 25.0331 16.9279C26.477 17.084 27.5892 18.1571 27.794 19.5913C27.8233 19.7279 27.8428 19.8547 27.8721 20.0596ZM23.9209 26.3326C25.5209 26.3326 27.0233 26.3424 28.5355 26.3229C28.6428 26.3229 28.8087 26.1375 28.8379 26.0107C28.9257 25.6009 28.955 25.1717 29.0135 24.7522C29.1404 23.6985 29.2672 22.6351 29.4038 21.5229C28.8574 21.5229 28.3696 21.5229 27.8916 21.5229C26.438 21.5229 26.438 21.5229 26.3794 20.0888C26.3502 19.4254 26.1063 18.8986 25.4331 18.5669C25.2868 19.9815 24.6234 21.1522 24.0185 22.362C23.9795 22.4498 23.9307 22.5473 23.9307 22.6351C23.9209 23.8449 23.9209 25.0546 23.9209 26.3326ZM20.799 26.3034C21.3454 26.3034 21.8722 26.3034 22.4088 26.3034C22.4088 25.2302 22.4088 24.1863 22.4088 23.1327C21.8527 23.1327 21.3258 23.1327 20.799 23.1327C20.799 24.2058 20.799 25.24 20.799 26.3034Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
  
  <script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 40,
    },
    height: {
      type: Number,
      required: false,
      default: 40,
    },
    value: {
      type: Number,
      required: false,
      default: 1,
    },
    color: {
      type: String,
      required: false,
      default: "#0090E7",
    },
    background: {
      type: String,
      required: false,
      default: "#FFFFFF",
    },
  },

  computed: {
    propContainer() {
      return { width: this.width + 10 + "px", height: this.height + 10 + "px" };
    },
  },

  data() {
    return {};
  },

  methods: {
    clickMe() {
      this.$emit("clickMe", this.value);
    },
  },
};
</script>
  
  <style scoped>
.container-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>