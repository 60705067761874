<template>
  <svg xmlns="http://www.w3.org/2000/svg" :style="{
    height:height,
    width:width
  }"  viewBox="0 0 401.84 463.93">
    <title>RED FLAG</title>
    <g id="Capa_2" data-name="Capa 2">
      <g id="Capa_1-2" data-name="Capa 1" s>
        <path
          d="M80.47,320.78V402c0,15.66-.08,31.33.08,47,0,3.08-1,4.75-3.62,6.28-19.76,11.59-39.39,11.59-58.83-.54a7.48,7.48,0,0,1-3-5.43c-.07-50.81.12-101.63.13-152.44,0-44.65-.17-89.3-.24-133.94,0-25.47.09-50.93-.13-76.39a13.92,13.92,0,0,0-2.92-7.93C-1,62.35-3.85,44.58,5.5,26S31-1,51.73.1C85,2,105.43,39.1,90,69.12c-.68,1.32-1.39,2.62-2.78,5.24,10.6,2.29,20.58,5.18,30.76,6.52,33.41,4.4,65.27-4.17,97.2-12.32,25-6.39,50-13.29,75.41-17.87,37.08-6.7,73.19-2.92,106.84,15.78,3.15,1.75,4.46,3.62,4.46,7.31q-.16,120.74-.12,241.46c0,1.3-.15,2.59-.27,4.64-4.93-2.68-9.44-5.05-13.88-7.55a125.93,125.93,0,0,0-47.51-15.42c-20.83-2.45-41.57-.28-61.87,4.55-27.41,6.53-54.57,14-81.94,20.72-24.78,6.07-49.93,10.15-75.49,7.49-11.79-1.23-23.35-4.7-35-7.2C84.18,322.13,82.66,321.49,80.47,320.78ZM35.75,256.54V431.46c0,10.53,2.71,13,13.47,12,1.82-.16,3.61-.61,5.42-.81,4.1-.46,5.68-2.25,5.67-6.8q-.23-178.67-.23-357.34a12.59,12.59,0,0,1,5.13-10.92,26.2,26.2,0,0,0,7.24-31.17C67.86,26.35,57,19.54,46.54,20.28c-11.33.8-21.43,8.52-24.65,18.85a26.56,26.56,0,0,0,9.77,29.72c3.27,2.45,4.15,5,4.14,8.79Q35.68,167.09,35.75,256.54Z"
        />
        <path
          class="cls-1"
          d="M35.75,256.54q0-89.45,0-178.9c0-3.78-.87-6.34-4.14-8.79a26.56,26.56,0,0,1-9.77-29.72C25.11,28.8,35.21,21.08,46.54,20.28,57,19.54,67.86,26.35,72.45,36.45a26.2,26.2,0,0,1-7.24,31.17,12.59,12.59,0,0,0-5.13,10.92q.21,178.66.23,357.34c0,4.55-1.57,6.34-5.67,6.8-1.81.2-3.6.65-5.42.81-10.76.93-13.47-1.5-13.47-12Z"
        />
        <path
          class="cls-2"
          d="M81.13,90.37V294.85s40.32,31.68,145-2.4,156.48-7.2,156.48-7.2V78.85S344.65,52,255.85,76,143.05,110.05,81.13,90.37Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "FlagIcon",
  props: {
    width: {
      type: String,
      required: false,
      default: '20px',
    },
    height: {
      type: String,
      required: false,
      default: '20px',
    },
  },
};
</script>

<style>
.cls-1 {
  fill: #f9b819;
}
.cls-2 {
  fill: #c1181a;
}

</style>