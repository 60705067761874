<template>
  <div>
    <div v-if="!loading" class="w-100 h-100">
      <div class="d-flex align-items-center" style="height: 20%">
        <div
          class="d-flex justify-content-between align-items-center w-100"
          style="height: 20%"
        >
          <div class="font-weight-bolder title">Attention Times</div>
          <div>
            <clock-icon
              :width="40"
              :height="40"
              :color="isDarkTheme ? 'white' : '#0090E7'"
              :background="isDarkTheme ? '#0090E7' : '#DCEAFF'"
            ></clock-icon>
          </div>
        </div>
      </div>
      <div style="margin: 0 3% 0 0">
        <div
          class="dotted-line"
          :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
        >
          <p class="text-detail">
            <span class="label">
              <feather-icon
                icon="AlertCircleIcon"
                class="text-info cursor-pointer"
                id="time-view"
              ></feather-icon>
              Average view time
            </span>
            <span id="pending-time" class="value">{{ pendingTime }}</span>
            <b-tooltip target="pending-time" variant="primary" noninteractive> {{ convertSeconds(totalPendingSeconds) }}</b-tooltip>
            <b-tooltip target="time-view" noninteractive
              >Average time to view the claim</b-tooltip
            >
          </p>
        </div>
        <div
          class="dotted-line"
          :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
        >
          <p class="text-detail">
            <span class="label">
              <feather-icon
                icon="AlertCircleIcon"
                class="text-info cursor-pointer"
                id="time-review"
              ></feather-icon>
              Average review time
            </span>
            <span id="end-time" class="value">{{ endTime }}</span>
            <b-tooltip target="end-time" variant="primary" noninteractive> {{ convertSeconds(totalEndSeconds) }}</b-tooltip>
            <b-tooltip target="time-review" noninteractive
              >Average time to review the claim</b-tooltip
            >
          </p>
        </div>
        <div
          class="dotted-line"
          :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
        >
          <p class="text-detail">
            <span class="label">
              <feather-icon
                icon="AlertCircleIcon"
                class="text-info cursor-pointer"
                id="time-attention"
              ></feather-icon>
              Average attention time
            </span>
            <span id="total-time" class="value">{{ totalTime }}</span>
            <b-tooltip target="total-time" variant="primary" noninteractive> {{ convertSeconds(totalTimeSeconds) }}</b-tooltip>
            <b-tooltip target="time-attention" noninteractive
              >Average time to attend claims</b-tooltip
            >
          </p>
        </div>
      </div>
    </div>
    <div v-else class="w-100 h-100">
      <div
        class="d-flex align-items-center"
        style="height: 20%; margin-bottom: 25px"
      >
        <div
          class="d-flex justify-content-between align-items-center w-100"
          style="height: 20%"
        >
          <div style="width: 70%">
            <b-skeleton animation="wave"></b-skeleton>
            </div>
          <div>
            <b-skeleton-img animation="wave" height="40px" width="40px"></b-skeleton-img>
          </div>
        </div>
      </div>
      <div style="margin: 0 3% 0 0">
        <div
          class="dotted-line"
        >
          <b-skeleton animation="wave"></b-skeleton>
        </div>
        <div
          class="dotted-line"
        >
          <b-skeleton animation="wave"></b-skeleton>
        </div>
        <div
          class="dotted-line"
        >
          <b-skeleton animation="wave"></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClockIcon from "@/icons/ClockIcon.vue";
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";

export default {
  components: {
    ClockIcon,
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
  },

  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },
  data() {
    return {
      pendingTime: null,
      endTime: null,
      totalTime: null,
      loading: null,
      totalPendingSeconds: 0,
      totalEndSeconds: 0,
      totalTimeSeconds: 0
    };
  },
  methods: {
    async getAvgTimeClaimsRequest() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          month: this.month,
          year: this.year,
        };
        const { data } = await DashboardClaimsService.getAvgTimeClaimsRequest(
          params
        );
        this.pendingTime = this.getMaxTime(data.pending);
        this.totalPendingSeconds = data.pending.seconds_total ? data.pending.seconds_total : 0;
        this.endTime = this.getMaxTime(data.end);
        this.totalEndSeconds = data.end.seconds_total ? data.end.seconds_total : 0;
        this.totalTime = this.getMaxTime(data.avg);
        this.totalTimeSeconds = data.avg.seconds_total ? data.avg.seconds_total : 0 ;

        this.loading = false;
      } catch (error) {
        this.showErrorSwall(error);
      }
    },

    getMaxTime(request_time) {
      if (request_time.weeks > 0) {
        const time = request_time.weeks == 1 ? " Week.." : " Weeks..";
        return Number(request_time.weeks).toFixed(0) + "" + time;
      }
      if (request_time.days > 0) {
        const time = request_time.days == 1 ? " Day.." : " Days..";
        return Number(request_time.days).toFixed(0) + "" + time;
      }

      if (request_time.hours > 0) {
        const time = request_time.hours == 1 ? " Hour.." : " Hours..";
        return Number(request_time.hours).toFixed(0) + "" + time;
      }

      if (request_time.minutes > 0) {
        const time = request_time.minutes == 1 ? " Minute.." : " Minutes..";
        return Number(request_time.minutes).toFixed(0) + "" + time;
      }

      if (request_time.seconds_total > 0) {
        const time =
          request_time.seconds_total == 1 ? " Second.." : " Seconds..";
        return Number(request_time.seconds_total).toFixed(0) + "" + time;
      }

      return " 0 Seconds";
    },

    convertSeconds(seconds) {
      var hours = Math.floor(seconds / 3600)
      var minutes = Math.floor((seconds % 3600) / 60)
      var secondsRes = seconds % 60
      secondsRes = secondsRes.toFixed(0);
      var result = ""

      if (hours > 0) {
        result += hours + (hours == 1 ?  " hour, " : " hours, " )
      }

      if (minutes > 0) {
        result += minutes + (minutes == 1 ?  " minute, " : " minutes, " )
      }

      result += secondsRes + " seconds"

      if (hours === 0 && minutes === 0) {
        result = secondsRes + " seconds"
      }

      return result
    },
  },

  async mounted() {
    await this.getAvgTimeClaimsRequest();
  },

  watch: {
    moduleId() {
      this.getAvgTimeClaimsRequest();
    },
    month() {
      this.getAvgTimeClaimsRequest();
    },
    year() {
      this.getAvgTimeClaimsRequest();
    },
  },
};
</script>

<style scoped lang="scss">
*,
*:after,
*:before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0 none;
  position: relative;
}

.title {
  color: #007dc8;
  font-size: 18px;
}
.dotted-line {
  margin: 15px 0;
}
.text-detail {
  background: inherit;
  width: 100%;
  font-size: 14px;
  line-height: 1.5rem;
  color: #535353;
  margin-bottom: 0.5rem;
}
.text-detail:before {
  content: "";
  position: absolute;
  bottom: 0.4rem;
  width: 100%;
  height: 0;
  line-height: 0;
  border-bottom: 2px dotted #0090e7;
}

.dark-layout {
  .label,
  .value {
    color: #adadad !important;
  }
}

.label {
  background: inherit;
  display: inline;
  z-index: 1;
  padding-right: 2px;
  color: #535353;
}

.text-info {
  padding-bottom: 2px;
}

.value {
  background: inherit;
  position: absolute;
  padding-left: 2px;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 2;
  color: #535353;
}

.my-bg-white {
  background-color: #ffffff;
}

.my-bg-white-dark {
  background-color: #17171a;
}

@media (min-width: 1651px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 14px;
  }
  .value {
    font-size: 14px;
  }
}

@media (min-width: 1521px) and (max-width: 1650px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 13px;
  }
  .value {
    font-size: 13px;
  }
}

@media (min-width: 1501px) and (max-width: 1520px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .title {
    font-size: 16px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .title {
    font-size: 15px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (min-width: 301px) and (max-width: 767px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (max-width: 300px) {
  .title {
    font-size: 16px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}
</style>