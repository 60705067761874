<template>
  <div class="container-icon" :style="propContainer" @click="clickMe()">
    <svg
      :width="width"
      :height="height"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="50" height="50" rx="10" :fill="background" />
      <path
        d="M34.4836 22.2028C34.1559 20.6803 33.5532 19.3799 32.6228 18.2486C29.9585 14.9816 26.5118 13.6072 22.3884 14.5058C18.1276 15.4362 15.4527 18.2063 14.5117 22.4777C13.243 28.2292 17.4298 34.002 23.2977 34.6257C27.5373 35.0698 30.8466 33.4099 33.2255 29.8892C33.2995 29.7729 33.3735 29.646 33.4581 29.5403C33.8598 29.0116 34.473 28.8953 35.0017 29.2231C35.5198 29.5508 35.6889 30.1958 35.3612 30.7561C34.6528 31.9931 33.7647 33.0821 32.6651 33.9914C25.2959 40.1341 14.3637 36.402 12.2809 27.0451C10.7373 20.0988 15.7382 12.9834 22.8113 12.1058C29.0704 11.3234 34.6634 14.9393 36.5347 20.9658C36.6616 21.3781 36.6933 21.9279 36.9682 22.1393C37.2431 22.3508 37.7823 22.1605 38.1947 22.2239C38.4801 22.2662 38.8819 22.3719 38.977 22.5623C39.0722 22.7526 38.903 23.1543 38.7339 23.3764C37.9515 24.3702 37.1268 25.3323 36.3127 26.305C35.8264 26.8971 35.5303 26.9076 35.0546 26.3261C34.2193 25.3323 33.3735 24.3385 32.5594 23.3129C32.4008 23.112 32.2845 22.742 32.3691 22.5305C32.4325 22.3614 32.8131 22.2451 33.0669 22.2134C33.5215 22.1711 33.9867 22.2028 34.4836 22.2028Z"
        :fill="color"
      />
      <path
        d="M25.6765 19.2953C25.6765 20.3314 25.6659 21.3781 25.6871 22.4143C25.6976 22.6469 25.8457 22.8795 25.9514 23.1015C26.0677 23.3129 26.3003 23.5032 26.332 23.7147C26.4166 24.2645 26.7972 24.4865 27.2201 24.7297C28.2985 25.3323 29.3558 25.9561 30.4237 26.5799C30.8783 26.8442 31.1109 27.246 31.0157 27.7746C30.9311 28.2504 30.6351 28.6204 30.1699 28.6733C29.8316 28.705 29.4298 28.6099 29.1338 28.4407C28.0025 27.8275 26.9135 27.1508 25.7822 26.5376C25.5708 26.4213 25.2536 26.379 25.0104 26.4213C24.1435 26.5905 23.2765 26.2522 22.8324 25.5015C22.3884 24.7508 22.5047 23.8204 23.065 23.1438C23.2448 22.9217 23.3822 22.6046 23.3928 22.3297C23.4245 20.268 23.4034 18.2063 23.4139 16.1446C23.4139 15.3411 23.8157 14.8759 24.4924 14.8442C25.1796 14.8125 25.6659 15.2988 25.6765 16.0389C25.6871 17.1173 25.6765 18.2063 25.6765 19.2953ZM25.3699 24.5077C25.3805 24.0425 25.0316 23.6618 24.5664 23.6407C24.1012 23.6195 23.6783 24.0213 23.6783 24.4865C23.6783 24.9306 24.0694 25.3218 24.5241 25.3218C24.9998 25.3323 25.3593 24.9729 25.3699 24.5077Z"
        :fill="color"
      />
    </svg>
  </div>
</template>
    
    <script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: 50,
    },
    height: {
      type: Number,
      required: false,
      default: 50,
    },
    value: {
      type: Number,
      required: false,
      default: 1,
    },
    color: {
      type: String,
      required: false,
      default: "#0090E7",
    },
    background: {
      type: String,
      required: false,
      default: "#FFFFFF",
    },
  },

  computed: {
    propContainer() {
      return { width: this.width + 10 + "px", height: this.height + 10 + "px" };
    },
  },

  data() {
    return {};
  },

  methods: {
    clickMe() {
      this.$emit("clickMe", this.value);
    },
  },
};
</script>
    
    <style scoped>
.container-icon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>