<template>
  <div
    style="height: 80%"
    class="w-100 d-flex align-items-center justify-content-center"
  >
    <vc-donut
      v-if="!loading"
      :sections="sections"
      :total="totalClaims"
      hasLegend
      :legendPlacement="'right'"
    >
      <div class="pie-total-x">{{ totalClaims }}</div>
      <div>Claims</div>
      <template #legend>
        <div class="cdc-legend">
          <div
            class="cdc-legend-item"
            v-for="(item, idx) in sections"
            :key="idx"
          >
            <feather-icon
              icon="SquareIcon"
              :style="{
                fill: item.color,
                color: item.color || customColors[idx],
                'margin-right': '5px',
              }"
            ></feather-icon>
            <span>{{ item.label }} ({{ item.value }})</span>
          </div>
        </div>
      </template>
    </vc-donut>
    <b-skeleton
      v-else
      type="avatar"
      animation="fade"
      class="my-pie-skeleton"
    ></b-skeleton>
  </div>
</template>
    
    <script>
import ECharts from "vue-echarts";
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";
import colors from '../colors.js';
export default {
  components: {
    ECharts,
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth;
    },
  },

  data() {
    return {
      loading: true,
      sections: [],
      totalClaims: 0,
      colors: colors,
    };
  },

  methods: {
    async getMostFrequentTypesRequest() {
      try {
        this.totalClaims = 0;
        this.sections = [];
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          month: this.month,
          year: this.year,
        };
        const { data } =
          await DashboardClaimsService.getMostFrequentTypesRequest(params);

        data.forEach((element) => {
          this.totalClaims += element.total;
        });
        this.sections = data.map((element, index) => ({
          value: element.total,
          label: element.description,
          color: this.colors[index],
        }));

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    await this.getMostFrequentTypesRequest();
  },

  watch: {
    moduleId() {
      this.getMostFrequentTypesRequest();
    },
    month() {
      this.getMostFrequentTypesRequest();
    },
    year() {
      this.getMostFrequentTypesRequest();
    },
  },
};
</script>
    
<style lang="scss">
.pie-total-x {
  font-size: 40px;
  font-family: "Rubick";
  font-weight: bold;
  margin: 0 !important;
  padding: 0 !important;
}

.my-pie-skeleton {
  height: 60%;
  width: 30%;
}

// .cdc-legend {
//   margin-left: 10% !important;
// }

.cdc-overlay {
  background-color: rgb(255, 255, 255) !important;
}
.dark-layout {
  .cdc-overlay {
    background-color: #1e1e1e !important;
  }
  .my-pie-skeleton {
    background-color: #080809;
  }
}

.cdc-legend {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1em;
  flex-wrap: wrap;
}
.cdc-legend-item {
  display: inline-flex;
  align-items: center;
  margin: 0.5em;
}
.cdc-container {
  margin-bottom: 90px;
  gap: 106px;
}

@media (max-width: 1201px) {
  .cdc-container {
    margin-bottom: 10%;
    margin-left: 10%;
    flex-direction: row;
  }
  .cdc-legend {
    margin-left: 10% !important;
  }
}

@media (max-width: 1200px) and (min-width: 768px) {
  .cdc-container {
    flex-direction: column;
    margin-bottom: 30%;
    margin-left: 0;
  }

  .cdc-legend {
    margin-top: 10% !important;
  }

  .my-pie-skeleton {
    height: 60%;
    width: 45%;
    margin-right: 20%;
    margin-bottom: 10%;
  }
}

@media (max-width: 767px) {
  .my-pie-skeleton {
    height: 60%;
    width: 45%;
    margin-left: 20%;
    margin-bottom: 10%;
  }
}
</style>