<template>
  <div>
    <div v-if="!loading">
      <div class="font-weight-bolder title">Employees with more Claims</div>
      <div class="podium">
        <div>
          <div class="red-flag number-position">
            <span style="margin-right: 1px">2nd</span>
            <red-flag-icon
              :height="firstFlagSize"
              :width="firstFlagSize"
            ></red-flag-icon>
          </div>
          <div
            class="position position-2"
            v-b-tooltip.hover
            :title="podium[1].full_name ? podium[1].full_name : ''"
            @click="openModalDetail"
          >
            <b-avatar
              :src="podium[1].image ? podium[1].image : ''"
              class="avatar-2"
            ></b-avatar>
          </div>
          <div class="number-requests text-center">
            <div
              class="comment-container"
              :class="
                isDarkTheme
                  ? 'comment-container-bg-dark'
                  : 'comment-container-bg'
              "
            >
              <div
                class="triangle"
                :class="
                  isDarkTheme ? 'triangle-border-dark' : 'triangle-border'
                "
              ></div>
              <div
                class="comment d-flex align-items-center justify-content-center"
              >
                {{ podium[1].total ? podium[1].total : 0 }}
                <angry-icon :width="14" :height="14"></angry-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="position-1-container">
          <div class="red-flag number-position">
            <span style="margin-right: 1px">1st</span>
            <red-flag-icon
              :height="firstFlagSize"
              :width="firstFlagSize"
            ></red-flag-icon>
          </div>
          <div
            class="position position-1"
            v-b-tooltip.hover
            :title="podium[0].full_name ? podium[0].full_name : ''"
            @click="openModalDetail"
          >
            <b-avatar
              :src="podium[0].image ? podium[0].image : ''"
              class="avatar-1"
            ></b-avatar>
          </div>
          <div class="number-requests text-center">
            <div
              class="comment-container"
              :class="
                isDarkTheme
                  ? 'comment-container-bg-dark'
                  : 'comment-container-bg'
              "
            >
              <div
                class="triangle"
                :class="
                  isDarkTheme ? 'triangle-border-dark' : 'triangle-border'
                "
              ></div>
              <div
                class="comment d-flex align-items-center justify-content-center"
              >
                {{ podium[0].total ? podium[0].total : 0 }}
                <angry-icon :width="14" :height="14"></angry-icon>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="red-flag number-position">
            <span style="margin-right: 1px">3rd</span>
            <red-flag-icon
              :height="firstFlagSize"
              :width="firstFlagSize"
            ></red-flag-icon>
          </div>
          <div
            class="position position-2"
            v-b-tooltip.hover
            :title="podium[2].full_name ? podium[2].full_name : ''"
            @click="openModalDetail"
          >
            <b-avatar
              :src="podium[2].image ? podium[2].image : ''"
              class="avatar-2"
            ></b-avatar>
          </div>
          <div class="number-requests text-center">
            <div
              class="comment-container"
              :class="
                isDarkTheme
                  ? 'comment-container-bg-dark'
                  : 'comment-container-bg'
              "
            >
              <div
                class="triangle"
                :class="
                  isDarkTheme ? 'triangle-border-dark' : 'triangle-border'
                "
              ></div>
              <div
                class="comment d-flex align-items-center justify-content-center"
              >
                {{ podium[2].total ? podium[2].total : 0 }}
                <angry-icon :width="14" :height="14"></angry-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="font-weight-bolder title">Employees with more Claims</div>
      <div class="podium">
        <div>
          <div class="red-flag number-position">
            <span style="margin-right: 1px">2nd</span>
            <red-flag-icon
              :height="firstFlagSize"
              :width="firstFlagSize"
            ></red-flag-icon>
          </div>
          <div class="position position-2">
            <b-skeleton type="avatar" class="avatar-2"></b-skeleton>
          </div>
          <div class="number-requests text-center">
            <div
              class="comment-container"
              :class="
                isDarkTheme
                  ? 'comment-container-bg-dark'
                  : 'comment-container-bg'
              "
            >
              <div
                class="triangle"
                :class="
                  isDarkTheme ? 'triangle-border-dark' : 'triangle-border'
                "
              ></div>
              <div
                class="comment d-flex align-items-center justify-content-center"
              >
                0
                <b-skeleton
                  type="avatar"
                  height="14px"
                  width="14px"
                  style="margin: 3px"
                ></b-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div class="position-1-container">
          <div class="red-flag number-position">
            <span style="margin-right: 1px">1st</span>
            <red-flag-icon
              :height="firstFlagSize"
              :width="firstFlagSize"
            ></red-flag-icon>
          </div>
          <div class="position position-1">
            <b-skeleton type="avatar" class="avatar-1"></b-skeleton>
          </div>
          <div class="number-requests text-center">
            <div
              class="comment-container"
              :class="
                isDarkTheme
                  ? 'comment-container-bg-dark'
                  : 'comment-container-bg'
              "
            >
              <div
                class="triangle"
                :class="
                  isDarkTheme ? 'triangle-border-dark' : 'triangle-border'
                "
              ></div>
              <div
                class="comment d-flex align-items-center justify-content-center"
              >
                0
                 <b-skeleton
                  type="avatar"
                  height="14px"
                  width="14px"
                  style="margin: 3px"
                ></b-skeleton>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="red-flag number-position">
            <span style="margin-right: 1px">3rd</span>
            <red-flag-icon
              :height="firstFlagSize"
              :width="firstFlagSize"
            ></red-flag-icon>
          </div>
          <div class="position position-2">
            <b-skeleton type="avatar" class="avatar-2"></b-skeleton>
          </div>
          <div class="number-requests text-center">
            <div
              class="comment-container"
              :class="
                isDarkTheme
                  ? 'comment-container-bg-dark'
                  : 'comment-container-bg'
              "
            >
              <div
                class="triangle"
                :class="
                  isDarkTheme ? 'triangle-border-dark' : 'triangle-border'
                "
              ></div>
              <div
                class="comment d-flex align-items-center justify-content-center"
              >
                0
                <b-skeleton
                  type="avatar"
                  height="14px"
                  width="14px"
                  style="margin: 3px"
                ></b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-survey-rating-request
      v-if="showModalDetail"
      :moduleId="moduleId"
      @close="showModalDetail = false"
    ></modal-survey-rating-request>
  </div>
</template>


<script>
import RedFlagIcon from "@/icons/FlagIcon.vue";
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";
import ModalSurveyRatingRequest from "@/views/commons/employee-claims/components/dashboard/modals/ModalSurveyRatingRequest.vue";
import AngryIcon from "@/icons/Angry2Icon.vue";
export default {
  components: {
    RedFlagIcon,
    ModalSurveyRatingRequest,
    AngryIcon,
  },

  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth;
    },

    firstFlagSize() {
      if (this.windowWidth > 1500) {
        return "20px";
      } else {
        return "15px";
      }
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },

  data() {
    return {
      loading: null,
      podium: [],
      showModalDetail: false,
    };
  },

  methods: {
    async getPodiumForClaimsRequest() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          month: this.month,
          year: this.year,
        };
        const { data } = await DashboardClaimsService.getPodiumFroClaimsRequest(
          params
        );

        if (data.length < 3) {
          const missingItems = 3 - data.length;

          // Agregar los objetos necesarios para alcanzar una longitud de 3
          for (let i = 0; i < missingItems; i++) {
            const newObj = { full_name: "", image: `img ${i + 4}`, total: 0 };
            data.push(newObj);
          }
        }
        this.podium = data;

        this.loading = false;
      } catch (error) {
        this.showErrorSwall(error);
      }
    },

    openModalDetail: function () {
      this.showModalDetail = true;
    },
  },

  async created() {
    await this.getPodiumForClaimsRequest();
  },

  watch: {
    moduleId() {
      this.getPodiumForClaimsRequest();
    },

    month() {
      this.getPodiumForClaimsRequest();
    },

    year() {
      this.getPodiumForClaimsRequest();
    },
  },
};
</script>

<style scoped>
.podium {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.my-text-dark-light {
  color: #525252;
  font-weight: 400;
}

.my-text-dark {
  color: #a9a9a9;
  font-weight: 400;
}

.position {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.position-1-container {
  margin: 0 30px 30px 30px;
}

.red-flag {
  display: flex;
  justify-content: center;
  direction: column;
  margin: 5px 0;
}

.title {
  color: #007dc8;
  font-weight: bold;
}

.comment-container {
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin: 8px 0 0 0;
  padding: 1px 0 0 0;
}

.triangle {
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
}

.triangle-border {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

.triangle-border-dark {
  border-top: 1px solid #808080;
  border-left: 1px solid #808080;
  background-color: #17171a;
}

.comment-container-bg {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.comment-container-bg-dark {
  border: 1px solid #808080;
}

.avatar-1 {
  border: 1px solid #0090e7;
}
.avatar-2 {
  border: 1px solid #0090e7;
}

.comment {
  padding: 0 0 0 5px;
  font-size: 10px;
}

@media (min-width: 1651px) {
  .title {
    font-size: 16px;
  }
  .number-position {
    font-size: 11px;
  }

  .number-requests {
    margin: 2px 0;
    font-size: 14px;
  }

  .avatar-1 {
    width: 60px;
    height: 60px;
  }
  .avatar-2 {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1401px) and (max-width: 1650px) {
  .title {
    font-size: 16px;
  }
  .number-position {
    font-size: 11px;
  }
  .number-requests {
    font-size: 13px;
  }

  .avatar-1 {
    width: 50px;
    height: 50px;
  }
  .avatar-2 {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .title {
    font-size: 16px;
  }
  .number-position {
    font-size: 11px;
  }
  .number-requests {
    font-size: 13px;
  }
  .avatar-1 {
    width: 50px;
    height: 50px;
  }
  .avatar-2 {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .title {
    font-size: 15px;
  }

  .number-position {
    font-size: 11px;
  }
  .number-requests {
    font-size: 13px;
  }
  .avatar-1 {
    width: 55px;
    height: 55px;
  }
  .avatar-2 {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 793px) and (max-width: 990px) {
  .title {
    font-size: 18px;
  }

  .number-requests {
    font-size: 13px;
  }

  .avatar-1 {
    width: 55px;
    height: 55px;
  }
  .avatar-2 {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 768px) and (max-width: 792px) {
  .title {
    font-size: 16px;
  }
  .number-position {
    font-size: 15px;
  }
  .number-requests {
    font-size: 15px;
  }

  .avatar-1 {
    width: 55px;
    height: 55px;
  }
  .avatar-2 {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 368px) and (max-width: 767px) {
  .title {
    font-size: 18px;
  }
  .number-position {
    font-size: 11px;
  }
  .number-requests {
    font-size: 13px;
  }
  .avatar-1 {
    width: 50px;
    height: 50px;
  }
  .avatar-2 {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 367px) {
  .title {
    font-size: 18px;
  }

  .number-requests {
    font-size: 12px;
  }

  .avatar-1 {
    width: 35px;
    height: 35px;
  }
  .avatar-2 {
    width: 27px;
    height: 27px;
  }
}
</style>