<template>
  <div>
    <dashboard-claims :moduleId="17"></dashboard-claims>
  </div>
</template>

<script>
import DashboardClaims from "@/views/commons/employee-claims/components/dashboard/DashboardClaims.vue";
export default {
components:{
    DashboardClaims
}
}
</script>

<style>

</style>