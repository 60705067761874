<template>
  <div>
    <div v-if="!loading">
      <div class="h-100">
        <div
          class="d-flex justify-content-between align-items-center"
          style="height: 20%; margin-top: -9px"
        >
          <div class="font-weight-bolder title">Overall Satisfaction</div>
          <div>
            <like-icon
              :color="isDarkTheme ? 'white' : '#0090E7'"
              :background="isDarkTheme ? '#0090E7' : '#DCEAFF'"
            ></like-icon>
          </div>
        </div>
        <div class="icons-container" style="height: 80%">
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <angry-emoji-icon
                  :width="iconSize"
                  :height="iconSize"
                ></angry-emoji-icon>
              </div>
              <div class="description">Bad</div>
            </div>
            <div class="my-border-red w-100 my-line">
              <div class="circle-position" v-if="greaterTotal == 0">
                <div class="my-circle-2"></div>
              </div>
            </div>
            <div class="text-center">{{ surveyRating[0].total }}</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <sad-emoji-icon
                  :width="iconSize"
                  :height="iconSize"
                ></sad-emoji-icon>
              </div>
              <div class="description">Regular</div>
            </div>
            <div class="my-border-red-light w-100 my-line">
              <div class="circle-position" v-if="greaterTotal == 1">
                <div class="my-circle-2"></div>
              </div>
            </div>
            <div class="text-center">{{ surveyRating[1].total }}</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <normal-emoji-icon
                  :width="iconSize"
                  :height="iconSize"
                ></normal-emoji-icon>
              </div>
              <div class="description">Good</div>
            </div>
            <div class="my-border-orange w-100 my-line">
              <div class="circle-position" v-if="greaterTotal == 2">
                <div class="my-circle-2"></div>
              </div>
            </div>
            <div class="text-center">{{ surveyRating[2].total }}</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <happy-emoji-icon
                  :width="iconSize"
                  :height="iconSize"
                ></happy-emoji-icon>
              </div>
              <div class="description">Very Good</div>
            </div>
            <div class="my-border-orange-light w-100 my-line">
              <div class="circle-position" v-if="greaterTotal == 3">
                <div class="my-circle-2"></div>
              </div>
            </div>
            <div class="text-center">{{ surveyRating[3].total }}</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <super-happy-emoji-icon
                  :width="iconSize"
                  :height="iconSize"
                ></super-happy-emoji-icon>
              </div>
              <div class="description">Excellent</div>
            </div>
            <div class="my-border-success w-100 my-line">
              <div class="circle-position" v-if="greaterTotal == 4">
                <div class="my-circle-2"></div>
              </div>
            </div>
            <div class="text-center">{{ surveyRating[4].total }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="h-100">
        <div
          class="d-flex justify-content-between align-items-center"
          style="height: 20%"
        >
          <div style="width: 80%">
            <b-skeleton></b-skeleton>
          </div>
          <div>
            <b-skeleton-img height="40px" width="40px"></b-skeleton-img>
          </div>
        </div>
        <div class="icons-container mt-1" style="height: 80%">
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <b-skeleton
                  type="avatar"
                  :width="iconSize + 'px'"
                  :height="iconSize + 'px'"
                ></b-skeleton>
              </div>
              <div class="description">Bad</div>
            </div>
            <b-skeleton></b-skeleton>
            <div class="text-center">0</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <b-skeleton
                  type="avatar"
                  :width="iconSize + 'px'"
                  :height="iconSize + 'px'"
                ></b-skeleton>
              </div>
              <div class="description">Regular</div>
            </div>
            <b-skeleton></b-skeleton>
            <div class="text-center">0</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <b-skeleton
                  type="avatar"
                  :width="iconSize + 'px'"
                  :height="iconSize + 'px'"
                ></b-skeleton>
              </div>
              <div class="description">Good</div>
            </div>
            <b-skeleton></b-skeleton>
            <div class="text-center">0</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <b-skeleton
                  type="avatar"
                  :width="iconSize + 'px'"
                  :height="iconSize + 'px'"
                ></b-skeleton>
              </div>
              <div class="description">Very Good</div>
            </div>
            <b-skeleton></b-skeleton>
            <div class="text-center">0</div>
          </div>
          <div class="icon-survey">
            <div class="">
              <div class="d-flex justify-content-center">
                <b-skeleton
                  type="avatar"
                  :width="iconSize + 'px'"
                  :height="iconSize + 'px'"
                ></b-skeleton>
              </div>
              <div class="description">Excellent</div>
            </div>
            <b-skeleton></b-skeleton>
            <div class="text-center">0</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";
import AngryEmojiIcon from "@/icons/AngryEmojiIcon.vue";
import SadEmojiIcon from "@/icons/SadEmojiIcon.vue";
import NormalEmojiIcon from "@/icons/NormalEmojiIcon.vue";
import HappyEmojiIcon from "@/icons/HappyEmojiIcon.vue";
import SuperHappyEmojiIcon from "@/icons/SuperHappyEmojiIcon.vue";
import LikeIcon from "@/icons/LikeIcon.vue";
export default {
  components: {
    AngryEmojiIcon,
    SadEmojiIcon,
    NormalEmojiIcon,
    HappyEmojiIcon,
    SuperHappyEmojiIcon,
    LikeIcon,
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth;
    },

    iconSize() {
      if (this.windowWidth > 1500) {
        return 30;
      }
      if (this.windowWidth > 901) {
        return 25;
      }
      if (this.windowWidth > 768) {
        return 40;
      } else {
        return 30;
      }
    },
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },

  data() {
    return {
      loading: null,
      surveyRating: [],
      greaterTotal: 0,
    };
  },

  methods: {
    async getSurveyRating() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          month: this.month,
          year: this.year,
        };
        const { data } = await DashboardClaimsService.getSurveyRatingForModule(
          params
        );

        this.greaterTotal = this.getMaxValue(data);

        this.surveyRating = data;
      } catch (error) {
        this.loading = false;
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },

    getMaxValue(array) {
      var maxValue = 0;
      var indexMax = 0;

      if (array.length <= 0) {
        return 4;
      }

      for (var i = 0; i < array.length; i++) {
        if (array[i].total > maxValue) {
          maxValue = array[i].total;
          indexMax = i;
        }
      }
      return indexMax;
    },
  },

  async created() {
    await this.getSurveyRating();
  },

  watch: {
    moduleId() {
      this.getSurveyRating();
    },

    month() {
      this.getSurveyRating();
    },

    year() {
      this.getSurveyRating();
    },
  },
};
</script>

<style scoped>
.icons-container {
  display: flex;
  padding-top: 5px;
}

.icon-survey {
  flex-basis: calc(33.33% - 10px);
}

.title {
  color: #007dc8;
}

.description {
  font-size: 9px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.my-line {
  margin: 10px 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.my-border-red {
  border-bottom: 7px solid #cc2334;
}

.my-border-red-light {
  border-bottom: 7px solid #ef4b3f;
}

.my-border-orange {
  border-bottom: 7px solid #ef8035;
}

.my-border-orange-light {
  border-bottom: 7px solid #efb025;
}

.my-border-success {
  border-bottom: 7px solid #63db1c;
}

.circle-position {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #f4f4f4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}

.my-circle-2 {
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-color: #ffffff;
}

@media (min-width: 1501px) {
  .title {
    font-size: 18px;
  }
  .description {
    font-size: 12px;
  }
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .title {
    font-size: 16px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .title {
    font-size: 15px;
  }
  .description {
    font-size: 8px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .title {
    font-size: 18px;
  }
  .description {
    font-size: 12px;
  }
}

@media (min-width: 301px) and (max-width: 767px) {
  .title {
    font-size: 18px;
  }
}

@media (max-width: 300px) {
  .title {
    font-size: 16px;
  }
}
</style>