<template>
  <div>
    <div v-if="!loading" class="w-100 h-100">
      <div
        class="d-flex align-items-center"
        style="height: 20%; margin-bottom: 25px"
      >
        <div
          class="d-flex justify-content-between align-items-center w-100"
          style="height: 20%"
        >
          <div class="font-weight-bolder title">Staff Claims Status</div>
          <div>
            <note-icon
              :width="40"
              :height="40"
              :color="isDarkTheme ? 'white' : '#0090E7'"
              :background="isDarkTheme ? '#0090E7' : '#DCEAFF'"
            ></note-icon>
          </div>
        </div>
      </div>
      <div style="height: 80%; margin: 10px 3% 0 0">
        <div>
          <div
            class="text-detail d-flex align-items-center justify-content-between"
          >
            <div class="label d-flex align-items-center">
              <div class="circle"></div>
              Awaiting claims
            </div>
            <span class="value">{{
              claimsRequest[0].total ? claimsRequest[0].total : 0
            }}</span>
          </div>
        </div>
        <div>
          <div
            class="text-detail d-flex align-items-center justify-content-between"
          >
            <div class="label d-flex align-items-center">
              <div class="circle"></div>
              Claims under review
            </div>
            <span class="value">{{
              claimsRequest[1].total ? claimsRequest[1].total : 0
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 h-100" v-else>
      <div class="d-flex align-items-center" style="height: 20%">
        <div
          class="d-flex justify-content-between align-items-center w-100"
          style="height: 20%"
        >
          <div style="width: 80%">
            <b-skeleton></b-skeleton>
          </div>
          <div>
            <b-skeleton-img height="40px" width="40px"></b-skeleton-img>
          </div>
        </div>
      </div>
      <div style="height: 80%; margin: 10px 3% 0 0" class="mt-2">
        <div>
          <b-skeleton></b-skeleton>
        </div>
        <div>
          <b-skeleton></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import NoteIcon from "@/icons/NoteIcon.vue";
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";

export default {
  components: {
    NoteIcon,
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      claimsRequest: [
        { status: 2, total: 0 },
        { status: 3, total: 0 },
      ],
      loading: null,
    };
  },
  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },
  methods: {
    async getClaimsForStatus() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          month: this.month,
          year: this.year,
        };
        const { data } = await DashboardClaimsService.getClaimsRequestForStatus(
          params
        );
        this.claimsRequest = data;
        this.loading = false;
      } catch (error) {
        this.showErrorSwall(error);
      }
    },

    openModalDetail: function () {
      this.showModalDetail = true;
    },
  },

  async created() {
    await this.getClaimsForStatus();
  },

  watch: {
    moduleId() {
      this.getClaimsForStatus();
    },
    month() {
      this.getClaimsForStatus();
    },
    year() {
      this.getClaimsForStatus();
    },
  },
};
</script>
  
  <style scoped lang="scss">
.title {
  color: #007dc8;
  font-size: 18px;
}

.text-detail {
  background: inherit;
  width: 100%;
  font-size: 14px;
  line-height: 1.5rem;
  color: #535353;
  margin-bottom: 0.5rem;
}

.circle {
  background-color: #0090e7;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 5px;
}

.dark-layout {
  .label,
  .value {
    color: #adadad !important;
  }
}

@media (min-width: 1651px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 16px;
  }
  .value {
    font-size: 16px;
  }
}

@media (min-width: 1521px) and (max-width: 1650px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 14px;
  }
  .value {
    font-size: 14px;
  }
}

@media (min-width: 1501px) and (max-width: 1520px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 13px;
  }
  .value {
    font-size: 13px;
  }
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .title {
    font-size: 16px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .title {
    font-size: 15px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (min-width: 301px) and (max-width: 767px) {
  .title {
    font-size: 18px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}

@media (max-width: 300px) {
  .title {
    font-size: 16px;
  }
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}
</style>