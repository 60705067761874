var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',[_c('div',{staticClass:"font-weight-bolder title"},[_vm._v("Employees with more Claims")]),_c('div',{staticClass:"podium"},[_c('div',[_c('div',{staticClass:"red-flag number-position"},[_c('span',{staticStyle:{"margin-right":"1px"}},[_vm._v("2nd")]),_c('red-flag-icon',{attrs:{"height":_vm.firstFlagSize,"width":_vm.firstFlagSize}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"position position-2",attrs:{"title":_vm.podium[1].full_name ? _vm.podium[1].full_name : ''},on:{"click":_vm.openModalDetail}},[_c('b-avatar',{staticClass:"avatar-2",attrs:{"src":_vm.podium[1].image ? _vm.podium[1].image : ''}})],1),_c('div',{staticClass:"number-requests text-center"},[_c('div',{staticClass:"comment-container",class:_vm.isDarkTheme
                ? 'comment-container-bg-dark'
                : 'comment-container-bg'},[_c('div',{staticClass:"triangle",class:_vm.isDarkTheme ? 'triangle-border-dark' : 'triangle-border'}),_c('div',{staticClass:"comment d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.podium[1].total ? _vm.podium[1].total : 0)+" "),_c('angry-icon',{attrs:{"width":14,"height":14}})],1)])])]),_c('div',{staticClass:"position-1-container"},[_c('div',{staticClass:"red-flag number-position"},[_c('span',{staticStyle:{"margin-right":"1px"}},[_vm._v("1st")]),_c('red-flag-icon',{attrs:{"height":_vm.firstFlagSize,"width":_vm.firstFlagSize}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"position position-1",attrs:{"title":_vm.podium[0].full_name ? _vm.podium[0].full_name : ''},on:{"click":_vm.openModalDetail}},[_c('b-avatar',{staticClass:"avatar-1",attrs:{"src":_vm.podium[0].image ? _vm.podium[0].image : ''}})],1),_c('div',{staticClass:"number-requests text-center"},[_c('div',{staticClass:"comment-container",class:_vm.isDarkTheme
                ? 'comment-container-bg-dark'
                : 'comment-container-bg'},[_c('div',{staticClass:"triangle",class:_vm.isDarkTheme ? 'triangle-border-dark' : 'triangle-border'}),_c('div',{staticClass:"comment d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.podium[0].total ? _vm.podium[0].total : 0)+" "),_c('angry-icon',{attrs:{"width":14,"height":14}})],1)])])]),_c('div',[_c('div',{staticClass:"red-flag number-position"},[_c('span',{staticStyle:{"margin-right":"1px"}},[_vm._v("3rd")]),_c('red-flag-icon',{attrs:{"height":_vm.firstFlagSize,"width":_vm.firstFlagSize}})],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"position position-2",attrs:{"title":_vm.podium[2].full_name ? _vm.podium[2].full_name : ''},on:{"click":_vm.openModalDetail}},[_c('b-avatar',{staticClass:"avatar-2",attrs:{"src":_vm.podium[2].image ? _vm.podium[2].image : ''}})],1),_c('div',{staticClass:"number-requests text-center"},[_c('div',{staticClass:"comment-container",class:_vm.isDarkTheme
                ? 'comment-container-bg-dark'
                : 'comment-container-bg'},[_c('div',{staticClass:"triangle",class:_vm.isDarkTheme ? 'triangle-border-dark' : 'triangle-border'}),_c('div',{staticClass:"comment d-flex align-items-center justify-content-center"},[_vm._v(" "+_vm._s(_vm.podium[2].total ? _vm.podium[2].total : 0)+" "),_c('angry-icon',{attrs:{"width":14,"height":14}})],1)])])])])]):_c('div',[_c('div',{staticClass:"font-weight-bolder title"},[_vm._v("Employees with more Claims")]),_c('div',{staticClass:"podium"},[_c('div',[_c('div',{staticClass:"red-flag number-position"},[_c('span',{staticStyle:{"margin-right":"1px"}},[_vm._v("2nd")]),_c('red-flag-icon',{attrs:{"height":_vm.firstFlagSize,"width":_vm.firstFlagSize}})],1),_c('div',{staticClass:"position position-2"},[_c('b-skeleton',{staticClass:"avatar-2",attrs:{"type":"avatar"}})],1),_c('div',{staticClass:"number-requests text-center"},[_c('div',{staticClass:"comment-container",class:_vm.isDarkTheme
                ? 'comment-container-bg-dark'
                : 'comment-container-bg'},[_c('div',{staticClass:"triangle",class:_vm.isDarkTheme ? 'triangle-border-dark' : 'triangle-border'}),_c('div',{staticClass:"comment d-flex align-items-center justify-content-center"},[_vm._v(" 0 "),_c('b-skeleton',{staticStyle:{"margin":"3px"},attrs:{"type":"avatar","height":"14px","width":"14px"}})],1)])])]),_c('div',{staticClass:"position-1-container"},[_c('div',{staticClass:"red-flag number-position"},[_c('span',{staticStyle:{"margin-right":"1px"}},[_vm._v("1st")]),_c('red-flag-icon',{attrs:{"height":_vm.firstFlagSize,"width":_vm.firstFlagSize}})],1),_c('div',{staticClass:"position position-1"},[_c('b-skeleton',{staticClass:"avatar-1",attrs:{"type":"avatar"}})],1),_c('div',{staticClass:"number-requests text-center"},[_c('div',{staticClass:"comment-container",class:_vm.isDarkTheme
                ? 'comment-container-bg-dark'
                : 'comment-container-bg'},[_c('div',{staticClass:"triangle",class:_vm.isDarkTheme ? 'triangle-border-dark' : 'triangle-border'}),_c('div',{staticClass:"comment d-flex align-items-center justify-content-center"},[_vm._v(" 0 "),_c('b-skeleton',{staticStyle:{"margin":"3px"},attrs:{"type":"avatar","height":"14px","width":"14px"}})],1)])])]),_c('div',[_c('div',{staticClass:"red-flag number-position"},[_c('span',{staticStyle:{"margin-right":"1px"}},[_vm._v("3rd")]),_c('red-flag-icon',{attrs:{"height":_vm.firstFlagSize,"width":_vm.firstFlagSize}})],1),_c('div',{staticClass:"position position-2"},[_c('b-skeleton',{staticClass:"avatar-2",attrs:{"type":"avatar"}})],1),_c('div',{staticClass:"number-requests text-center"},[_c('div',{staticClass:"comment-container",class:_vm.isDarkTheme
                ? 'comment-container-bg-dark'
                : 'comment-container-bg'},[_c('div',{staticClass:"triangle",class:_vm.isDarkTheme ? 'triangle-border-dark' : 'triangle-border'}),_c('div',{staticClass:"comment d-flex align-items-center justify-content-center"},[_vm._v(" 0 "),_c('b-skeleton',{staticStyle:{"margin":"3px"},attrs:{"type":"avatar","height":"14px","width":"14px"}})],1)])])])])]),(_vm.showModalDetail)?_c('modal-survey-rating-request',{attrs:{"moduleId":_vm.moduleId},on:{"close":function($event){_vm.showModalDetail = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }