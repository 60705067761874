<template>
  <b-modal
    id="modal-scrollable"
    v-model="showModal"
    title="Collaborators with more Claims"
    @hidden="closeModal"
    hide-footer
    modal-class="claims-modal"
  >
    <b-row>
      <b-col cols="12" class="mb-1">
        <b-row>
          <b-col xl="3" lg="3" md="6" sm="6" cols="6">
            <div>
              <label for="datepicker-from" class="my-color-secondary font-18"
                >From:</label
              >
              <b-form-datepicker
                id="datepicker-from"
                size="sm"
                class="mb-1"
                placeholder="Date"
                :date-format-options="formatDate"
                v-model="dateFrom"
              ></b-form-datepicker>
            </div>
          </b-col>
          <b-col xl="3" lg="3" md="6" sm="6" cols="6">
            <div>
              <label for="datepicker-to" class="my-color-secondary font-18"
                >To:</label
              >
              <b-form-datepicker
                id="datepicker-to"
                :date-format-options="formatDate"
                size="sm"
                class="mb-1"
                placeholder="Date"
                v-model="dateTo"
              ></b-form-datepicker>
            </div>
          </b-col>
          <b-col xl="3" lg="3" md="6" sm="6" cols="6">
            <label for="modules" class="my-color-secondary font-18"
              >Module:</label
            >
            <b-form-select
              size="sm"
              id="modules"
              v-model="workerModule"
              :options="modules"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col xl="3" lg="3" md="6" sm="6" cols="6">
            <div class="d-flex">
              <div>
                <label for="" class="my-color-secondary font-18">Items:</label>
                <b-form-input
                  type="number"
                  size="sm"
                  v-model="topNumber"
                ></b-form-input>
              </div>
              <div class="d-flex align-items-center text-primary">
                <feather-icon
                  icon="SearchIcon"
                  class="mx-1 mt-2 cursor-pointer"
                  size="20"
                  @click="getPodiumForClaimsRequest"
                ></feather-icon>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        xl="6"
        lg="6"
        md="12"
        sm="12"
        cols="12"
        style="height: 500px; overflow-y: auto"
      >
        <b-row>
          <b-col>
            <div
              class="mb-1 my-color-primary font-23 font-weight-bolder"
              style="margin-left: 2px"
            >
              Workers
            </div>
          </b-col>
          <b-col
            cols="12"
            class="cursor-pointer pl-1"
            v-for="(item, index) in podium"
            :key="item.id"
            @click="viewDetailUser(item)"
          >
            <div class="d-flex my-mb">
              <div
                class="number"
                :class="userActive.id == item.id ? 'my-user-active' : ''"
              >
                #{{ index + 1 }}
              </div>
              <div
                class="d-flex align-items-center my-card"
                :class="userActive.id == item.id ? 'my-user-active' : ''"
              >
                <div style="width: 75%" class="d-flex align-items-center">
                  <b-avatar class="ml-1" :src="item.image"></b-avatar>
                  <div class="ml-1">
                    <div class="font-21">{{ item.full_name }}</div>
                    <!-- <div>
                      <b-badge pill style="font-size: 10px">
                        {{ item.module }}
                      </b-badge>
                    </div> -->
                  </div>
                </div>
                <div style="width: 25%">
                  <span class="my-color-primary font-21 font-weight-bolder"
                    >{{ item.total }}
                    {{ item.total == 1 ? "Claim" : "Claims" }}</span
                  >
                </div>
              </div>
            </div>
          </b-col >
        </b-row>
      </b-col>

      <b-col xl="6" lg="6" md="12" sm="12" cols="12">
        <div style="margin-top: 5px" v-if="!workerLoading && loading">
          <p class="h3 text-center my-color-primary font-23 font-weight-bolder">
            Claims Profile
          </p>
          <div class="w-100 h-100 d-flex justify-content-center mt-1">
            <b-avatar size="80px" :src="userActive.image"></b-avatar>
          </div>
          <div class="my-1">
            <div class="text-center font-21 my-color-dark">
              {{ userActive.full_name }}
            </div>
            <div class="text-center">
              <b-badge variante="outline-primary" style="font-size: 10px">
                {{ userActive.module }}
              </b-badge>
            </div>
          </div>

          <div class="mt-2 d-flex px-1">
            <div class="card-height-container">
              <div class="card-height">
                <div class="font-15 my-color-dark">Pending</div>
                <div class="font-30 my-color-primary font-weight-bolder">
                  {{ currentWorkerDetail[1].total_status }}
                </div>
              </div>
            </div>
            <div class="card-height-container">
              <div class="card-height">
                <div class="font-15 my-color-dark">Revision</div>
                <div class="font-30 my-color-primary font-weight-bolder">
                  {{ currentWorkerDetail[2].total_status }}
                </div>
              </div>
            </div>
            <div class="card-height-container">
              <div class="card-height">
                <div class="font-15 my-color-dark">Completed</div>
                <div class="font-30 my-color-primary font-weight-bolder">
                  {{
                    currentWorkerDetail[3].total_status +
                    currentWorkerDetail[4].total_status
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 10px" v-if="workerLoading && loading">
          <b-skeleton></b-skeleton>
          <div class="w-100 h-100 d-flex justify-content-center mt-1">
            <b-skeleton type="avatar" width="80px" height="80px"></b-skeleton>
          </div>
          <div class="my-1">
            <div class="text-center font-21 my-color-dark">
              <b-skeleton></b-skeleton>
            </div>
            <div class="text-center">
             <b-skeleton></b-skeleton>
            </div>
          </div>

          <div class="mt-2 d-flex px-1">
            <div class="card-height-container">
              <b-skeleton-img no-aspect height="90px"></b-skeleton-img>
            </div>
            <div class="card-height-container">
              <b-skeleton-img no-aspect height="90px"></b-skeleton-img>
            </div>
            <div class="card-height-container">
              <b-skeleton-img no-aspect height="90px"></b-skeleton-img>
            </div>
          </div>
        </div>
        <div class="h-100" v-if="!loading">
          <div
            class="d-flex align-items-center justify-content-center"
            style="height: 60%"
          >
            <b-avatar size="90px"></b-avatar>
          </div>
          <div class="d-flex justify-content-center" style="height: 40%">
            <div class="d-flex justify-content-center">Select a worker</div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";
import RedFlagIcon from "@/icons/FlagIcon.vue";
export default {
  components: { RedFlagIcon },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: true,
      loading: true,
      workerLoading: true,
      podium: [],
      userActive: 0,
      formatDate: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      modules: [],
      topNumber: 15,
      dateTo: null,
      dateFrom: null,
      workerModule: null,
      currentWorker: null,
      currentWorkerDetail: [],
    };
  },

  methods: {
    async getPodiumForClaimsRequest() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          top: this.topNumber,
          from: this.dateFrom,
          to: this.dateTo,
          worker_module: this.workerModule,
        };
        const { data } = await DashboardClaimsService.getPodiumFroClaimsRequest(
          params
        );
        this.podium = data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getModules() {
      try {
        const { data } = await DashboardClaimsService.getModulesForClaims();
        this.modules = data;
      } catch (error) {
        console.log(error);
      }
    },

    async viewDetailUser(item) {
      try {
        this.loading = true;
        this.workerLoading = true;
        this.userActive = item;
        const params = {
          worker_id: item.id,
          module_id: this.moduleId,
        };
        const { data } = await DashboardClaimsService.getDetailClaimsForWorker(
          params
        );
        this.currentWorkerDetail = data;
        this.workerLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    closeModal() {
      this.$emit("close");
    },
  },

  async created() {
    await this.getModules();
    await this.getPodiumForClaimsRequest();
  },
};
</script>

<style  lang="scss">
.claims-modal {
  font-family: "Rubick";
  .modal-body {
    background-color: #fdfdfd;
  }

  .my-color-primary {
    color: #007dc8;
  }

  .my-color-secondary {
    color: #7a7a7a;
  }

  .font-30 {
    font-size: 30px;
  }

  .font-23 {
    font-size: 20px;
  }

  .font-21 {
    font-size: 16px;
  }

  .font-18 {
    font-size: 13px;
  }

  .my-card {
    width: 90%;
    border-radius: 5px;
    height: 60px;
    margin-left: 5px;
    background-color: #c6e9ff;
    color: #202020;
  }

  .card-height-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .card-height {
    width: 80%;
    height: 90px;
    background-color: #a4e5ff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .my-user-active {
    background-color: #82cfff;
    color: #202020;
  }

  .number {
    width: 40px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c6e9ff;
    color: #202020;
    border-radius: 5px;
  }

  .my-color-dark {
    color: #202020;
  }

  .my-mb {
    margin-bottom: 8px;
  }
}

.dark-layout {
  .claims-modal {
    font-family: "Rubick";

    .modal-body {
      background-color: #111113;
    }

    .number {
      color: #989898 !important;
      background-color: #17171a;
    }
    .my-card {
      color: #989898 !important;
      background-color: #17171a;
    }

    .my-user-active {
      background-color: #37373e;
      color: #e8dfdf;
    }

    .card-height {
      background-color: #17171a;
    }
    .my-color-dark {
      color: #989898;
    }
  }
}
</style>