<template>
  <div>
    <header-slot> </header-slot>
    <div class="d-flex justify-content-between">
      <div>
        <v-select
          v-if="moduleId == 16"
          class="ml-1"
          :options="optionsModules"
          v-model="currentModuleId"
          label="name"
          :reduce="(option) => option.id"
        ></v-select>
      </div>
      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="btn-all-claims"
          :to="{ name: $route.meta.tabOthersClaimsRoute }"
        >
          <span style="margin-right: 5px"> All Claims </span>
          <b-badge
            v-if="CounterEmployee.counterModule > 0"
            pill
            variant="danger"
          >
            {{
              CounterEmployee.counterModule > 99
                ? "99+"
                : CounterEmployee.counterModule
            }}
          </b-badge>
        </b-button>
        <b-button
          variant="primary"
          class="ml-1"
          :to="{ name: $route.meta.tabEmployeeClaimListRoute }"
        >
          My Claims
        </b-button>
        <b-button variant="primary" class="ml-1" @click="updateAllCard()">
          <feather-icon icon="RefreshCcwIcon" /> Refresh
        </b-button>
        <v-select
          class="ml-1"
          :options="optionsMonths"
          v-model="month"
          label="name"
          :reduce="(option) => option.id"
        ></v-select>
        <v-select
          class="ml-1"
          :options="optionsYears"
          v-model="year"
          label="name"
          :reduce="(option) => option.id"
        ></v-select>
      </div>
    </div>
    <div class="dashboard-container">
      <b-row>
        <b-col xl="3" lg="3" md="6" xs="6" cols="12" class="mt-1">
          <div
            class="my-card-claims responsive-padding"
            :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
          >
            <claims-times
              ref="cardClaimsTimes"
              :moduleId="currentModuleId"
              :month="month"
              :year="year"
            ></claims-times>
          </div>
        </b-col>
        <b-col xl="3" lg="3" md="6" xs="6" cols="12" class="mt-1">
          <div
            class="my-card-claims responsive-padding"
            :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
          >
            <claims-request-status
              ref="cardClaimsStatus"
              :moduleId="currentModuleId"
              :month="month"
              :year="year"
            ></claims-request-status>
          </div>
        </b-col>
        <b-col xl="3" lg="3" md="6" xs="6" cols="12" class="mt-1">
          <div
            class="my-card-claims responsive-padding"
            :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
          >
            <survey-rating-claim-request
              ref="cardClaimsSurvey"
              :moduleId="currentModuleId"
              :month="month"
              :year="year"
            ></survey-rating-claim-request>
          </div>
        </b-col>
        <b-col xl="3" lg="3" md="6" xs="6" cols="12" class="mt-1">
          <div
            class="my-card-claims responsive-padding"
            :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
          >
            <podium
              ref="cardClaimsPodium"
              :moduleId="currentModuleId"
              :month="month"
              :year="year"
            ></podium>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-2" xl="6" lg="6" md="6" sm="12" cols="12">
          <div
            class="my-card-claims-chart"
            :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
          >
            <div class="title-chart" style="padding: 4% 13%">
              <div>Staff Claims by Type</div>
            </div>
            <!-- <donut-chart-claims
              ref="donutChartClaims"
              :moduleId="currentModuleId"
              :month="month"
              :year="year"
            /> -->
            <pie-chart-claims
              ref="pieChartClaims"
              :moduleId="currentModuleId"
              :month="month"
              :year="year"
            />
          </div>
        </b-col>
        <b-col class="mt-2" xl="6" lg="6" md="6" sm="12" cols="12">
          <div
            class="my-card-claims-chart"
            :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
          >
            <div class="title-chart" style="padding: 4% 10%">
              <div>Staff Claims per Month</div>
            </div>
            <bar-chart-claims
              ref="barChartClaims"
              :moduleId="currentModuleId"
              :month="month"
              :year="year"
            />
          </div>
        </b-col>
        <b-col
          class="mt-2"
          xl="6"
          lg="6"
          md="6"
          sm="12"
          cols="12"
          v-if="(isCeo || isChief || isSupervisor) && isAssignCardModule"
        >
          <div
            class="my-card-claims-chart"
            :class="isDarkTheme ? 'my-bg-white-dark' : 'my-bg-white'"
          >
            <DepartmentExpenses
              :showDate="true"
              :yearDash="year"
              :monthDash="month"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BarChartClaims from "@/views/commons/employee-claims/components/dashboard/Charts/BarChart.vue";
import PieChartClaims from "@/views/commons/employee-claims/components/dashboard/Charts/PieChart.vue";
import DonutChartClaims from "@/views/commons/employee-claims/components/dashboard/Charts/DonutChart.vue";
import Podium from "@/views/commons/employee-claims/components/dashboard/Cards/Podium.vue";
import SurveyRatingClaimRequest from "@/views/commons/employee-claims/components/dashboard/Cards/SurveyRatingClaimRequest.vue";
import ClaimsTimes from "@/views/commons/employee-claims/components/dashboard/Cards/ClaimsTimes.vue";
import ClaimsRequestStatus from "@/views/commons/employee-claims/components/dashboard/Cards/ClaimsRequestStatus.vue";
import DepartmentExpenses from "@/views/commons/expenses/components/department/DepartmentExpenses.vue";
import ExpensesService from "@/views/commons/expenses/services/expenses-department.service.js";

export default {
  components: {
    BarChartClaims,
    PieChartClaims,
    Podium,
    SurveyRatingClaimRequest,
    ClaimsTimes,
    ClaimsRequestStatus,
    DonutChartClaims,
    DepartmentExpenses,
  },
  props: {
    moduleId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
    }),
    currentRoleId() {
      return this.currentUser.role_id;
    },

    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    programId() {
      return this.$route.matched[0].meta.program;
    },
  },

  data() {
    return {
      month: 1,
      year: 2023,
      optionsMonths: [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "June" },
        { id: 7, name: "July" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sep" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" },
      ],
      optionsModules: [
        { id: 17, name: "RR.HH" },
        { id: 19, name: "LOGISTIC" },
      ],
      optionsYears: [],
      currentModuleId: this.moduleId == 16 ? 17 : this.moduleId,
      isAssignCardModule: false,
    };
  },

  methods: {
    currentMonth() {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      this.month = this.optionsMonths.filter(
        (element) => element.id == currentMonth
      )[0].id;
    },

    generateYears() {
      const today = new Date();
      const currentYear = today.getFullYear();
      const yearPrevius = [];

      for (let i = 0; i < 10; i++) {
        const lastYear = currentYear - i;
        yearPrevius.push(lastYear);
      }

      this.optionsYears = yearPrevius.map((element) => ({
        id: element,
        name: "" + element,
      }));
      this.year = this.optionsYears[0].id;
    },

    async updateAllCard() {
      await Promise.all([
        this.$refs["cardClaimsTimes"].getAvgTimeClaimsRequest(),
        this.$refs["cardClaimsStatus"].getClaimsForStatus(),
        this.$refs["cardClaimsSurvey"].getSurveyRating(),
        this.$refs["cardClaimsPodium"].getPodiumForClaimsRequest(),
        this.$refs["barChartClaims"].getClaimsRequestForMonth(),
        this.$refs["pieChartClaims"].getMostFrequentTypesRequest(),
      ]);
    },
    async existCardModule() {
      try {
        const { exists_card_expenses } = await ExpensesService.existCardModule({
          module_id: this.moduleId,
          program_id: this.programId,
        });
        this.isAssignCardModule = exists_card_expenses;
      } catch (error) {}
    },
  },
  mounted() {
    this.existCardModule();
    this.generateYears();
    this.currentMonth();
  },

  watch: {
    month(val) {
      if (val == null) {
        return;
      }
      this.updateAllCard();
    },
    year(val) {
      if (val == null) {
        return;
      }
      this.updateAllCard();
    },
  },
};
</script >

<style scoped>
.dashboard-container {
  font-family: "Rubick";
}

.my-bg-white {
  background-color: #ffffff;
}

.my-bg-white-dark {
  background-color: #17171a;
}

.my-card-claims {
  border-radius: 15px;
  height: 200px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* padding: 20px 40px; */
}

.my-card-claims-chart {
  border-radius: 10px;
  height: 500px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.title-chart {
  font-weight: bolder;
  color: #007dc8;
  font-size: 25px;
}

@media (min-width: 1501px) {
  .font-title {
    font-size: 18px;
  }
  .responsive-padding {
    padding: 20px 25px;
  }
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .font-title {
    font-size: 16px;
  }
  .responsive-padding {
    padding: 20px 22px;
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .font-title {
    font-size: 14px;
  }
  .responsive-padding {
    padding: 15px 20px;
  }
}

@media (min-width: 768px) and (max-width: 990px) {
  .font-title {
    font-size: 18px;
  }
  .responsive-padding {
    padding: 20px 30px;
  }
}

@media (min-width: 501px) and (max-width: 767px) {
  .font-title {
    font-size: 18px;
  }
  .responsive-padding {
    padding: 20px 40px 0 40px;
  }
}

@media (min-width: 301px) and (max-width: 500px) {
  .font-title {
    font-size: 18px;
  }
  .responsive-padding {
    padding: 30px 20px 0 20px;
  }
}

@media (max-width: 300px) {
  .font-title {
    font-size: 16px;
  }
  .responsive-padding {
    padding: 15px 15px 0 15px;
  }
}
</style>

<style lang="scss" >
.btn-all-claims {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
