<template>
  <div style="height: 80%" class="d-flex align-items-center justify-content-center">
    <e-charts ref="line" :options="option" autoresize />
  </div>
</template>
  
<script>
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";
import ECharts from "vue-echarts";

export default {
  components: {
    ECharts,
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
  },

  computed: {
    isDarkTheme() {
      return this.$store.state.appConfig.layout.skin === "dark";
    },
  },
  data() {
    return {
      loading: null,
      option: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Resolved", "Rejected"],
          color: ["#525252", "#F04242"],
          textStyle: {
            color: function (params) {
              // Obtener el color de las barras según el índice
              return option.color[params.dataIndex];
            },
          },
        },
        toolbox: {
          show: true,
          top: "center",
        },
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],

            axisLabel: {
              color: function (value, index) {
                return "#525252";
              },
              margin: 14,
              fontSize: 14,
              fontWeight: "500",
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              margin: 10,
              fontSize: 14,
              fontWeight: "500",
              color: function (value, index) {
                return "#525252";
              },
            },
            axisLine: {
              show: true, // Oculta las líneas del eje Y
            },
            splitLine: {
              show: true, // Muestra las líneas horizontales de la cuadrícula
              lineStyle: {
                color: "#525252",
                type: "dashed", // Cambia el tipo de línea de la cuadrícula a 'dashed'
                width: 1.3,
                interval: 10, // Cambia el desplazamiento de la línea de la cuadrícula
              },
            },
          },
        ],
        series: [
          {
            name: "Resolved",
            type: "bar",
            barGap: 0,
            color: "#63DB1C",
            label: {
              show: true,
              position: "top",
              distance: 12,
              align: "center",
              verticalAlign: "middle",
              rotate: 1,
              formatter: "{c}",
              fontSize: 12,
              rich: {
                name: {},
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [7, 7, 0, 0],
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
          {
            name: "Rejected",
            type: "bar",
            color: "#F04242",
            label: {
              show: true,
              position: "top",
              distance: 12,
              align: "center",
              verticalAlign: "middle",
              rotate: 1,
              formatter: "{c}",
              fontSize: 12,
              rich: {
                name: {},
              },
            },
            itemStyle: {
              normal: {
                barBorderRadius: [7, 7, 0, 0],
              },
            },
            emphasis: {
              focus: "series",
            },
            data: [],
          },
        ],
      },
    };
  },

  watch: {
    isDarkTheme: function (newValue) {},
  },

  methods: {
    async getClaimsRequestForMonth() {
      try {
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          month: this.month,
          year: this.year,
        };
        const { data } = await DashboardClaimsService.getClaimRequestForMonths(
          params
        );
        this.option.xAxis[0].data = data.months.map((month) => month.month);
        this.option.series[0].data = data.resolved.map(
          (series) => series.total
        );
        this.option.series[1].data = data.rejected.map(
          (series) => series.total
        );
      } catch (error) {
        this.loading = false;
        this.showErrorSwal(error);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    await this.getClaimsRequestForMonth();
  },

  watch: {
    moduleId() {
      this.getClaimsRequestForMonth();
    },
    month() {
      this.getClaimsRequestForMonth();
    },
    year() {
      this.getClaimsRequestForMonth();
    },
  },
};
</script>
  
<style>
.echarts {
  width: 90% !important;
  margin: auto;
}
</style>
  