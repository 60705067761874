import { amgApi } from "@/service/axios";

class DashboardEmployeeClaimsService {
    async getMostFrequentTypesRequest(params) {
        try {
            const data = await amgApi.post(
                "/commons/employee-claims/get-most-frequent-types-request",
                params
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

    async getPodiumFroClaimsRequest(params) {
        try {
            const data = await amgApi.post(
                "/commons/employee-claims/get-podium-claim-request",
                params
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

    async getClaimRequestForMonths(params) {
        try {
            const data = await amgApi.post(
                "/commons/employee-claims/get-claim-request-for-months",
                params
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

    async getSurveyRatingForModule(params) {
        try {
            const data = await amgApi.post(
                "/commons/employee-claims/get-survey-rating-for-module",
                params
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

    async getModulesForClaims() {
        try {
            const data = await amgApi.get(
                "/commons/employee-claims/get-modules"
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

    async getDetailClaimsForWorker(params) {
        try {
            const data = await amgApi.post(
                "/commons/employee-claims/get-detail-claims-for-worker", params
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

    async getClaimsRequestForStatus(params) {
        try {
            const data = await amgApi.post(
                "/commons/employee-claims/get-claims-request-for-status", params
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

    async getAvgTimeClaimsRequest(params) {
        try {
            const data = await amgApi.post(
                "/commons/employee-claims/get-avg-time-pending-claims-request", params
            );
            return data;
        } catch (err) {
            throw err;
        }
    }

}

export default new DashboardEmployeeClaimsService();
