<template>
  <div
    style="height: 80%"
    class="w-100 d-flex align-items-center justify-content-center"
  >
    <canvas id="chartJSContainer" width="600" height="400"></canvas>
    <!-- <vc-donut
      v-if="!loading"
      :sections="sections"
      :total="totalClaims"
      hasLegend
      :legendPlacement="'right'"
    >
      <div class="pie-total-x">{{ totalClaims }}</div>
      <div>Claims</div>
    </vc-donut> -->
    <!-- <b-skeleton
      v-else
      type="avatar"
      animation="fade"
      class="my-pie-skeleton"
    ></b-skeleton> -->
  </div>
</template>
    
    <script>
import ECharts from "vue-echarts";
import Chart from "chart.js";
import DashboardClaimsService from "@/views/commons/employee-claims/components/dashboard/DashboardEmployeesClaims.service.js";
export default {
  components: {
    ECharts,
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
    month: {
      type: Number,
      required: false,
    },
    year: {
      type: Number,
      required: false,
    },
  },

  computed: {
    windowWidth() {
      return this.$store.state.app.windowWidth;
    },
  },

  data() {
    return {
      loading: true,
      sections: [],
      totalClaims: 0,
      colors: ["#44C9D8", "#D92020", "#EC643C", "#334754"],
      options: {
        type: "doughnut",
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [
            {
              label: "# of Votes",
              data: [12, 19, 3, 5, 2, 3],
              backgroundColor: [
                "Red",
                "Blue",
                "Yellow",
                "Green",
                "Purple",
                "Orange",
              ],
            },
          ],
        },
        options: {
          cutoutPercentage: 80,
          elements: {
            arc: {
              borderWidth: 3,
              borderRadius: 10,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            afterDraw: function (chart) {
              var width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;

              ctx.restore();
              var fontSize = (height / 150).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.textBaseline = "middle";
              ctx.fillStyle = "#000000";
              var text = "Texto en el centro";
              var textX = Math.round((width - ctx.measureText(text).width) / 2);
              var textY = height / 2;
              ctx.fillText(text, textX, textY);
              ctx.save();
            },
          },
        },
      },
    };
  },

  methods: {
    createChart() {
      var ctx = document.getElementById("chartJSContainer").getContext("2d");
      new Chart(ctx, this.options);
    },
    async getMostFrequentTypesRequest() {
      try {
        this.totalClaims = 0;
        this.sections = [];
        this.loading = true;
        const params = {
          module_id: this.moduleId,
          month: this.month,
          year: this.year,
        };
        const { data } =
          await DashboardClaimsService.getMostFrequentTypesRequest(params);

        data.forEach((element) => {
          this.totalClaims += element.total;
        });
        this.sections = data.map((element, index) => ({
          value: element.total,
          label: element.description,
          color: this.colors[index],
        }));

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },

  async mounted() {
    this.createChart();
    // await this.getMostFrequentTypesRequest();
  },

  watch: {
    moduleId() {
      this.getMostFrequentTypesRequest();
    },
    month() {
      this.getMostFrequentTypesRequest();
    },
    year() {
      this.getMostFrequentTypesRequest();
    },
  },
};
</script>
    
<style lang="scss">
</style>